import { bankService, walletService } from '../services'

const state = {
  banks: null,
  status: null,
  SaBanks: [
    { name: 'ABSA', branch: '632005' },
    { name: 'African Bank', branch: '430000' },
    { name: 'Bank of Athens', branch: '410506' },
    { name: 'Bidvest Bank', branch: '462005' },
    { name: 'Capitec Bank', branch: '470010' },
    { name: 'Discovery Bank', branch: '679000' },
    { name: 'First National Bank', branch: '254005' },
    { name: 'Investec', branch: '580105' },
    { name: 'Ithala SOC Limited', branch: '757026' },
    { name: 'Merchantile Bank', branch: '450105' },
    { name: 'Nedbank', branch: '198765' },
    { name: 'SASFIN Bank Limited', branch: '683000' },
    { name: 'Standard Bank', branch: '051001' },
    { name: 'SA Post Bank', branch: '460005' },
    { name: 'Tyme Bank', branch: '678910' }
  ],
  StitchBanks: [
    { name: "absa", truzo: "ABSA" },
    { name: "african_bank", truzo: "African Bank" },
    { name: "za_bidvest", truzo: "Bidvest Bank" },
    { name: "capitec", truzo: "Capitec Bank" },
    { name: "discovery_bank", truzo: "Discovery Bank" },
    { name: "fnb", truzo: "First National Bank" },
    { name: "investec", truzo: "Investec" },
    { name: "za_ithala_bank", truzo: "Ithala SOC Limited" },
    { name: "za_mercantile_bank", truzo: "Mercantile Bank" },
    { name: "nedbank", truzo: "Nedbank" },
    { name: "sasfin_bank", truzo: "SASFIN Bank Limited" },
    { name: "standard_bank", truzo: "Standard Bank" },
    { name: "za_postbank", truzo: "SA Post Bank" },
    { name: "tymebank", truzo: "Tyme Bank" }
  ],
  AccountTypes: ['Cheque / Current', 'Savings', 'Transmission', 'Bond', 'Subscription Share']
}

const actions = {
  get ({ commit, dispatch }, currency) {
    commit('loading')

    if (currency) {
      bankService.get(currency)
        .then(
          banks => {
            if (banks && banks.length > 0) {
              commit('loaded', banks)
            } else {
              commit('notFound')
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  getWalletBanks ({ commit, dispatch }, currency) {
    commit('loading')

    if (currency) {
      walletService.getWalletBankDetails(currency)
        .then(
          banks => {
            if (banks && banks.length > 0) {
              commit('loaded', banks)
            } else {
              commit('notFound')
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  getAll ({ commit, dispatch }) {
    commit('loading')

    bankService.getAll()
      .then(
        banks => {
          if (banks && banks.length > 0) {
            commit('loaded', banks)
          } else {
            commit('notFound')
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.banks = null
    state.status = 'loading'
  },
  loaded (state, banks) {
    state.banks = banks
    state.status = 'loaded'
  },
  notFound (state) {
    state.banks = null
    state.status = 'not-found'
  },
  failed (state) {
    state.banks = null
    state.status = 'failed'
  },
  clear (state) {
    state.banks = null
    state.status = null
  }
}

export const banks = {
  namespaced: true,
  state,
  actions,
  mutations
}
